import moment from 'moment';
import { toast } from 'react-toastify';

import { AppThunkAction, AppThunkDispatch } from 'types';
import { DispatchJob } from 'types/Dispatch';
import { Job } from 'types/Job';

import { baseToastConfig } from 'Constants/app';
import { ReleaseReason } from 'Constants/job';
import { ShiftStatus, ShiftUpdateSpecialStatus } from 'Constants/worker';
import { JobsAPI } from 'Services/API';
import {
  JobAlertData,
  ReleaseSelectedWorkersRequestData,
  SplitJobData,
  UpdateJobVerificationRequest,
  UpdatePOSData,
} from 'Services/API/JobsAPI.service';
import { showErrorMessage } from 'Utils/errorMessage';
import { getUpdatedPOFields } from 'Utils/job';
import { getASAPdiff } from 'Utils/sorting';
import { FILTERS, objectValuesFilter } from 'Utils/valueFilter';
import app_history from 'app_history';

import { retrieve as retrieveZones } from '../zones/actions';
import * as actionTypes from './actionTypes';
import {
  GET_JOBS_REQUEST,
  GET_JOBS_ERROR,
  GET_JOBS_SUCCESS,
  CONFIRM_JOB_REQUEST,
  CONFIRM_JOB_SUCCESS,
  CONFIRM_JOB_ERROR,
  REPLACE_JOB_WORKER_REQUEST,
  REPLACE_JOB_WORKER_SUCCESS,
  REPLACE_JOB_WORKER_ERROR,
  UPDATE_FILTERS,
  SORT_ASAP,
  UPDATE_PO_NUMBER_REQUEST,
  UPDATE_PO_NUMBER_SUCCESS,
  UPDATE_PO_NUMBER_ERROR,
  CALCULATE_JOBS_STATS,
  RETRIEVE_JOB_REQUEST,
  RETRIEVE_JOB_SUCCESS,
  RETRIEVE_JOB_ERROR,
  UPDATE_JOB_REQUEST,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_ERROR,
  CLEAR_JOB,
  UPDATE_JOB_WORKERS_REQUEST,
  UPDATE_JOB_WORKERS_SUCCESS,
  UPDATE_JOB_WORKERS_ERROR,
  ASSIGN_WORKERS_TO_JOB,
  GET_JOBS_LOCATIONS_REQUEST,
  GET_JOBS_LOCATIONS_ERROR,
  GET_JOBS_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS_FILTERS,
  RETRIEVE_LOCATION_JOB_REQUEST,
  RETRIEVE_LOCATION_JOB_SUCCESS,
  RETRIEVE_LOCATION_JOB_ERROR,
  ADD_WORKER_REQUEST,
  ADD_WORKER_SUCCESS,
  ADD_WORKER_ERROR,
  UPDATE_JOB_WORKER_REQUEST,
  UPDATE_JOB_WORKER_SUCCESS,
  UPDATE_JOB_WORKER_ERROR,
  JOB_UPLOAD_IMAGE_REQUEST,
  JOB_UPLOAD_IMAGE_SUCCESS,
  JOB_UPLOAD_IMAGE_ERROR,
  JOB_CREATE_REQUEST,
  JOB_CREATE_SUCCESS,
  JOB_CREATE_ERROR,
  UPDATE_JOB_STATUS_REQUEST,
  UPDATE_JOB_STATUS_SUCCESS,
  UPDATE_JOB_STATUS_ERROR,
  ADD_JOB_LOCATION_REQUEST,
  ADD_JOB_LOCATION_SUCCESS,
  ADD_JOB_LOCATION_ERROR,
  UPDATE_JOB_LOCATION_REQUEST,
  UPDATE_JOB_LOCATION_SUCCESS,
  UPDATE_JOB_LOCATION_ERROR,
  ADD_SHIFT_REQUEST,
  ADD_SHIFT_SUCCESS,
  ADD_SHIFT_ERROR,
  DELETE_JOB_LOCATION_REQUEST,
  DELETE_JOB_LOCATION_SUCCESS,
  DELETE_JOB_LOCATION_ERROR,
  RECONSTRUCT_JOB_REQUEST,
  RECONSTRUCT_JOB_SUCCESS,
  RECONSTRUCT_JOB_ERROR,
} from './actionTypes';

export const FILTERS_STORAGE_KEY = 'jobs.filters';
export const LOCATIONS_FILTERS_STORAGE_KEY = 'jobs_locations.filters';

export function retrieve(): any {
  return async function (dispatch, getState) {
    try {
      /*const processing = getState().jobs.processing;
      if (processing) {
        return;
      }*/
      const processing_key = Math.random();
      dispatch({ type: GET_JOBS_REQUEST, processing_key: processing_key });
      const state = getState();
      const { requestDate, searchByEffectiveRequestDate, ...search_options } = state.jobs.search_options.asMutable();
      if (search_options) {
        if (requestDate) {
          search_options[searchByEffectiveRequestDate ? 'effectiveRequestDate' : 'requestDate'] =
            JSON.stringify(requestDate);
        }
        if (search_options.hasOwnProperty('createdDate')) {
          search_options.createdDate = JSON.stringify(search_options.createdDate);
        }
        if (
          search_options.hasOwnProperty('requestor') &&
          search_options.requestor &&
          search_options.requestor.hasOwnProperty('id')
        ) {
          search_options.requestor = search_options.requestor.id;
        }
        if (
          search_options.hasOwnProperty('department') &&
          search_options.department &&
          search_options.department.hasOwnProperty('id')
        ) {
          search_options.department = search_options.department.id;
        }
        if (
          search_options.hasOwnProperty('worker') &&
          search_options.worker &&
          search_options.worker.hasOwnProperty('id')
        ) {
          search_options.workerId = search_options.worker.id;
          search_options.worker = '';
        }
      }

      const response = await JobsAPI.getJobs(
        objectValuesFilter(search_options, [null, undefined, '', FILTERS.empty_array])
      );
      dispatch({
        type: GET_JOBS_SUCCESS,
        processing_key: processing_key,
        response: response,
      });
      dispatch(calculateStats());
    } catch (error) {
      dispatch({ type: GET_JOBS_ERROR });
    }
  };
}

export function retrieveLocations(search_options = null): any {
  return async function (dispatch, getState) {
    try {
      // const processing = getState().jobs.locations_processing;
      // if(processing){
      //     return;
      // }
      const state = getState();

      const zones = state.zones;

      const processing_key = Math.random();
      dispatch({
        type: GET_JOBS_LOCATIONS_REQUEST,
        processing_key: processing_key,
      });
      if (search_options == null) {
        search_options = state.jobs.locations_search_options.asMutable();
      }

      if (search_options.unconfirmed === true && parseInt(zones.stats.total_unconfirmed_jobs) === 0) {
        search_options.unconfirmed = '';
      }
      if (search_options.workers_unconfirmed === true && parseInt(zones.stats.today_unconfirmed_asap_shifts) === 0) {
        search_options.workers_unconfirmed = '';
      }

      search_options.from_job_finished_date = search_options.from_datetime;
      search_options.to_job_finished_date = search_options.to_datetime;

      if (search_options.filterByCompletionDate) {
        delete search_options.from_datetime;
        delete search_options.to_datetime;
      } else {
        delete search_options.from_job_finished_date;
        delete search_options.to_job_finished_date;
      }

      const response = await JobsAPI.getShifts(objectValuesFilter(search_options, [undefined, null]));

      dispatch({
        type: GET_JOBS_LOCATIONS_SUCCESS,
        processing_key: processing_key,
        jobs: response.jobs.data,
        total: response.jobs.total,
      });

      const { stats } = await JobsAPI.getLocationsFiltersStats(search_options);
      dispatch({
        type: actionTypes.GET_JOBS_LOCATIONS_FILTERS_STATS,
        stats,
      });
    } catch (error) {
      dispatch({ type: GET_JOBS_LOCATIONS_ERROR });
    }
  };
}

export function initFilters(): any {
  return function (dispatch) {
    const storage_filters = JSON.parse(localStorage.getItem(LOCATIONS_FILTERS_STORAGE_KEY)) || {};
    delete storage_filters.from_datetime;
    delete storage_filters.to_datetime;
    dispatch({ type: UPDATE_LOCATIONS_FILTERS, filters: storage_filters });
    dispatch(retrieveLocations());
  };
}

export function updateLocationsFilters(search_options): AppThunkAction<void> {
  return function (dispatch, getState) {
    const current_search_options = getState().jobs.locations_search_options;
    const new_search_options = { ...current_search_options, ...search_options };

    dispatch({ type: UPDATE_LOCATIONS_FILTERS, filters: new_search_options });
    dispatch(retrieveLocations(new_search_options));
    const savedFilters: typeof current_search_options = {
      ...new_search_options,
      from_datetime: moment().format('YYYY-MM-DD'),
      to_datetime: moment().format('YYYY-MM-DD'),
      search: undefined,
      page: undefined,
      job_types: undefined,
      job_statuses: undefined,
      worker_statuses: undefined,
      order_by_field: undefined,
      order_direction: 'asc',
    };
    localStorage.setItem(LOCATIONS_FILTERS_STORAGE_KEY, JSON.stringify(savedFilters));
  };
}

export function retrieveLocationJob(
  job_location: Partial<DispatchJob> & { job_id: number }
): AppThunkAction<Promise<DispatchJob>> {
  return async function (dispatch, getState) {
    try {
      const selected_location_id = job_location.id || getState().jobs.selected_location_id;
      dispatch({
        type: RETRIEVE_LOCATION_JOB_REQUEST,
        job_id: job_location.job_id,
        selected_location_id,
      });

      const isStandbyJob = job_location.is_standby;
      const originalJobID = job_location.original_standby_job_id;
      const needToRetrieveOriginalJob = Boolean(isStandbyJob && originalJobID);

      const jobID = needToRetrieveOriginalJob ? originalJobID : job_location.job_id;

      const response = await JobsAPI.getDispatchJobDetails(jobID);
      dispatch({
        type: RETRIEVE_LOCATION_JOB_SUCCESS,
        job: response.job,
      });
      return response.job;
    } catch (error) {
      dispatch({ type: RETRIEVE_LOCATION_JOB_ERROR });
      throw error;
    }
  };
}

export function reconstructJob(job_id = 0, job_body = {}) {
  return async function (dispatch) {
    try {
      dispatch({ type: RECONSTRUCT_JOB_REQUEST });
      const response = await JobsAPI.reconstructJob(job_id, job_body);
      dispatch({ type: RECONSTRUCT_JOB_SUCCESS, job: response.job });
      dispatch(retrieveLocations());
      return response;
    } catch (error) {
      dispatch({ type: RECONSTRUCT_JOB_ERROR });
      throw error;
    }
  };
}

export function integrationRerouteJob(job_id = 0, params = {}) {
  return async function (dispatch) {
    try {
      dispatch({ type: actionTypes.INTEGRATION_REROUTE_REQUEST });

      const response = await JobsAPI.integrationReroute(job_id, params);
      dispatch({
        type: actionTypes.INTEGRATION_REROUTE_SUCCESS,
        job: response.job,
      });
      dispatch(retrieveLocations());
      return response;
    } catch (error) {
      dispatch(retrieveLocations());
      dispatch(retrieveLocationJob({ job_id }));
      dispatch({ type: actionTypes.INTEGRATION_REROUTE_ERROR });
      throw error;
    }
  };
}

export function addWorker(job_id, data): any {
  return async function (dispatch) {
    try {
      dispatch({ type: ADD_WORKER_REQUEST });
      const response = await JobsAPI.addWorker(job_id, data);
      dispatch({ type: ADD_WORKER_SUCCESS, job: response.job });
      dispatch(retrieveJob(job_id, ''));
      dispatch(updateDispatchPageJobs(job_id, true));
      return response;
    } catch (error) {
      dispatch({ type: ADD_WORKER_ERROR });
      throw error;
    }
  };
}

export function retrieveJob(jobId, workerId?, showDeletedNotes = false): any {
  return async function (dispatch) {
    try {
      const params = {
        ...(showDeletedNotes && { show_deleted_notes: showDeletedNotes }),
        ...(workerId && { workerId }),
      };

      dispatch({ type: RETRIEVE_JOB_REQUEST });
      const response = await JobsAPI.getJob({ jobId, ...params });
      dispatch({ type: RETRIEVE_JOB_SUCCESS, job: response });
      return response;
    } catch (error) {
      dispatch({ type: RETRIEVE_JOB_ERROR });
      throw error;
    }
  };
}
export function clearJob(): any {
  return function (dispatch) {
    dispatch({ type: CLEAR_JOB });
  };
}

//
export function updateJob(job_id, data): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_JOB_REQUEST });
      const response = await JobsAPI.updateJob(job_id, data);
      dispatch({ type: UPDATE_JOB_SUCCESS, job: response });
      dispatch(updateDispatchPageJobs(job_id));
      dispatch(retrieveJob(job_id, ''));
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_JOB_ERROR });
      throw error;
    }
  };
}

export function updateJobImages(job_id, data): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      const response = await JobsAPI.updateJobImages(job_id, data);
      dispatch(retrieveJob(job_id, ''));
      const dispatchJob = getState().jobs.location_job;
      if (dispatchJob?.id === job_id) {
        dispatch(retrieveLocationJob({ job_id }));
        dispatch(retrieveLocations());
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function updateJobPDFs(job_id, data): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      const response = await JobsAPI.uploadPDFs(job_id, data);
      dispatch(retrieveJob(job_id, ''));
      const dispatchJob = getState().jobs.location_job;
      if (dispatchJob?.id === job_id) {
        dispatch(retrieveLocationJob({ job_id }));
        dispatch(retrieveLocations());
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function updateJobDeptAndSection(job_id: string | number, data: Partial<Job>): AppThunkAction<Promise<Job>> {
  return async function (dispatch, getState) {
    try {
      const response = await JobsAPI.updateJobDeptAndSection(job_id, data);
      dispatch(retrieveJob(job_id, ''));
      const dispatchJob = getState().jobs.location_job;
      if (dispatchJob?.id === job_id) {
        dispatch(retrieveLocationJob({ job_id: Number(job_id) }));
        dispatch(retrieveLocations());
      }
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function updateJobStatus(
  job_id: string | number,
  data: { status: string; note?: string; value?: number; timesheet_pdfs?: string[]; release_reason?: ReleaseReason }
): AppThunkAction<Promise<any>> {
  return async function (dispatch) {
    try {
      dispatch({ type: UPDATE_JOB_STATUS_REQUEST });
      const response = await JobsAPI.updateJobStatus(job_id, data);
      dispatch({ type: UPDATE_JOB_STATUS_SUCCESS, job: response.job });
      dispatch(retrieveJob(response.job.id, 0));
      dispatch(updateDispatchPageJobs(job_id, true));
      if (data && data.status === 'confirmed') {
        setTimeout(() => {
          dispatch(retrieveZones());
        }, 1500);
      }
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_JOB_STATUS_ERROR });
      throw error;
    }
  };
}

export function splitJob(job_id: number, data: SplitJobData): any {
  return async function (dispatch: AppThunkDispatch) {
    try {
      const response = await JobsAPI.splitJob(job_id, data);
      dispatch(retrieveLocationJob({ job_id }));
      dispatch(retrieveLocations());
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function undoSplitJob(job_id: number, splitJobID: number): any {
  return async function (dispatch: AppThunkDispatch) {
    try {
      const response = await JobsAPI.undoSplitJob(job_id, splitJobID);
      dispatch(retrieveLocationJob({ job_id }));
      dispatch(retrieveLocations());
      return response;
    } catch (error) {
      throw error;
    }
  };
}

type UpdateShiftData =
  | { status: ShiftStatus }
  | { status: ShiftUpdateSpecialStatus.re_route; location_id: number }
  | {
      status: ShiftUpdateSpecialStatus.edit;
      start_at: string;
      finish_at: string;
    }
  | { status: ShiftUpdateSpecialStatus };

export function updateJobWorker(
  job_id: string | number,
  job_worker_id: string | number,
  data: UpdateShiftData
): AppThunkAction<Promise<{ job: Job }>> {
  return async function (dispatch) {
    try {
      let shift = null;
      if (
        data.status &&
        [
          'pending',
          'assigned',
          'en_route',
          'on_location',
          'secured',
          'cannot_secure',
          'cancelled',
          'crew_arrived',
          'review',
          'review_finished',
        ].indexOf(data.status) !== -1
      ) {
        shift = {
          id: job_worker_id,
          status: data.status === ShiftUpdateSpecialStatus.cancel ? ShiftStatus.cancelled : data.status,
        };
      }
      dispatch({ type: UPDATE_JOB_WORKER_REQUEST });
      const response = await JobsAPI.updateJobShift(job_id, job_worker_id, data);
      dispatch({ type: UPDATE_JOB_WORKER_SUCCESS, job: response.job, shift });
      dispatch(retrieveLocations());
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_JOB_WORKER_ERROR });
      throw error;
    }
  };
}

export function updateUnconfirmCancel(job_id, job_worker_id): any {
  return async function (dispatch) {
    try {
      let shift = null;
      shift = {
        id: job_worker_id,
        unconfirmed_cancel: 0,
      };
      dispatch({ type: UPDATE_JOB_WORKER_REQUEST });
      const response = await JobsAPI.updateUnconfirmedCancel(job_id, job_worker_id);
      dispatch({ type: UPDATE_JOB_WORKER_SUCCESS, job: response.job, shift });
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_JOB_WORKER_ERROR });
      throw error;
    }
  };
}

export function addShift(job_id, location_id): any {
  return async function (dispatch) {
    try {
      dispatch({ type: ADD_SHIFT_REQUEST });
      const response = await JobsAPI.addShift(job_id, location_id);
      dispatch({ type: ADD_SHIFT_SUCCESS, job: response.job });
      dispatch(updateDispatchPageJobs(job_id, true));
      return response;
    } catch (error) {
      dispatch({ type: ADD_SHIFT_ERROR });
      throw error;
    }
  };
}

export function removeShift(job_id, job_worker_id): AppThunkAction<Promise<void>> {
  return async function (dispatch) {
    try {
      await JobsAPI.removeShift(job_id, job_worker_id);
      dispatch(updateDispatchPageJobs(job_id));
    } catch (error) {
      throw error;
    }
  };
}

export function revertShiftStatus(job_id, job_worker_id): AppThunkAction<Promise<void>> {
  return async function (dispatch) {
    try {
      await JobsAPI.revertShiftStatus(job_id, job_worker_id);
      dispatch(updateDispatchPageJobs(job_id));
    } catch (error) {
      throw error;
    }
  };
}

export function updateJobWorkers(job_id, worker): any {
  return async function (dispatch) {
    try {
      dispatch({ type: UPDATE_JOB_WORKERS_REQUEST });
      const response = await JobsAPI.updateShift(job_id, worker);
      dispatch({ type: UPDATE_JOB_WORKERS_SUCCESS, job: { ...response, workerAction: worker } });
      dispatch(updateDispatchPageJobs(job_id));
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_JOB_WORKERS_ERROR });
      throw error;
    }
  };
}

export function addJobLocation(job_id, data): any {
  return async function (dispatch) {
    try {
      dispatch({ type: ADD_JOB_LOCATION_REQUEST });
      const response = await JobsAPI.addLocation(job_id, data);
      dispatch({ type: ADD_JOB_LOCATION_SUCCESS, job: response.job });
      dispatch(updateDispatchPageJobs(job_id, true));
      return response;
    } catch (error) {
      dispatch({ type: ADD_JOB_LOCATION_ERROR });
      throw error;
    }
  };
}

export function updateJobLocation(job_id, data, location_id): any {
  return async function (dispatch) {
    try {
      dispatch({ type: UPDATE_JOB_LOCATION_REQUEST });
      const response = await JobsAPI.updateLocation(job_id, location_id, data);
      dispatch({ type: UPDATE_JOB_LOCATION_SUCCESS, job: response.job });
      dispatch(updateDispatchPageJobs(job_id, true));
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_JOB_LOCATION_ERROR });
      throw error;
    }
  };
}

export function deleteJobLocation(job_id, location_id): any {
  return async function (dispatch) {
    try {
      dispatch({ type: DELETE_JOB_LOCATION_REQUEST });
      const response = await JobsAPI.deleteLocation(job_id, location_id);
      dispatch({ type: DELETE_JOB_LOCATION_SUCCESS, job: { response, job_id, location_id } });
      return response;
    } catch (error) {
      dispatch({ type: DELETE_JOB_LOCATION_ERROR });
      throw error;
    }
  };
}

export function calculateStats(): any {
  return function (dispatch, getState) {
    const jobs = getState().jobs.jobs.results;
    const total_jobs = jobs.length;
    let total_workers_assigned = 0;
    let total_workers_needed = 0;
    jobs.forEach((job) => {
      total_workers_assigned += job.workers.length;
      total_workers_needed += job.maxWorkers;
    });
    const stats = {
      total_jobs,
      total_workers_assigned,
      total_workers_needed,
    };
    dispatch({ type: CALCULATE_JOBS_STATS, stats });
  };
}

export function confirmJobs(job_ids): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: CONFIRM_JOB_REQUEST });
      await JobsAPI.confirmJobs(job_ids);
      dispatch({ type: CONFIRM_JOB_SUCCESS, job_ids });
      return;
    } catch (error) {
      dispatch({ type: CONFIRM_JOB_ERROR });
      throw error;
    }
  };
}

export function releaseWorkers(job_id = 0, release_reason: ReleaseReason) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.RELEASE_JOB_WORKERS_REQUEST });
      await JobsAPI.releaseWorkers({
        job_id,
        release_reason,
      });
      dispatch({ type: actionTypes.RELEASE_JOB_WORKERS_SUCCESS });
      dispatch(retrieveJob(job_id, ''));
      dispatch(updateDispatchPageJobs(job_id));
      return;
    } catch (error) {
      dispatch({ type: actionTypes.RELEASE_JOB_WORKERS_ERROR, error });
      throw error;
    }
  };
}

type ReleaseSelectedWorkersProps = ReleaseSelectedWorkersRequestData & {
  comment?: string;
};

export function releaseSelectedWorkers({ comment, ...releaseWorkersRequestData }: ReleaseSelectedWorkersProps) {
  return async function (dispatch) {
    try {
      await JobsAPI.releaseSelectedWorkers({ ...releaseWorkersRequestData });
      dispatch(retrieveJob(releaseWorkersRequestData.job_id, ''));
      dispatch(updateDispatchPageJobs(releaseWorkersRequestData.job_id));
      if (comment) {
        await dispatch(updateJobStatus(releaseWorkersRequestData.job_id, { status: 'note', note: comment }));
        toast.success('Comment left successful', baseToastConfig);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };
}

export function updatePOS(posData: UpdatePOSData): AppThunkAction<Promise<string>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_PO_NUMBER_REQUEST });
      await JobsAPI.updatePOS(posData);

      dispatch({
        type: UPDATE_PO_NUMBER_SUCCESS,
        job_ids: posData.ids || [],
        po_number: posData.newPo || null,
        requisition: posData.requisition || null,
        receipt: posData.receipt || null,
        wr: posData.wr || null,
        account_no: posData.account_no || null,
        update_po: posData.update_po || false,
        update_requisition: posData.update_requisition || false,
        update_receipt: posData.update_receipt || false,
        update_wr: posData.update_wr || false,
        update_account_no: posData.update_account_no || false,
      });
      return getUpdatedPOFields(posData);
    } catch (error) {
      dispatch({ type: UPDATE_PO_NUMBER_ERROR });
      throw error;
    }
  };
}

export function updateFilters(search_options): any {
  return function (dispatch) {
    const storage_filters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    dispatch({
      type: UPDATE_FILTERS,
      filters: { ...storage_filters, ...search_options },
    });
    dispatch(retrieve());
  };
}

export function assignWorkersToJob(workers): any {
  return function (dispatch) {
    dispatch({ type: ASSIGN_WORKERS_TO_JOB, workers: workers });
  };
}

export function sortASAP(): any {
  return function (dispatch, getState) {
    const jobs = getState().jobs.jobs.results;
    const temp = [];

    jobs.forEach((project) => {
      const diff = getASAPdiff(project.start_at, project.created_at);
      if (diff <= 60 && (project.status === 'new' || project.status === 'in_progress')) {
        temp.unshift(project);
      } else {
        temp.push(project);
      }
    });

    dispatch({ type: SORT_ASAP, jobs: temp });
  };
}

// works
export function uploadImages(images): any {
  return async function (dispatch) {
    try {
      dispatch({ type: JOB_UPLOAD_IMAGE_REQUEST });
      const response = await JobsAPI.uploadImages(images);
      dispatch({ type: JOB_UPLOAD_IMAGE_SUCCESS, img_data: response });
    } catch (error) {
      dispatch({ type: JOB_UPLOAD_IMAGE_ERROR });
      throw error;
    }
  };
}

//
export function createJob(data): any {
  return async function (dispatch) {
    try {
      dispatch({ type: JOB_CREATE_REQUEST });
      const response = await JobsAPI.createJob(data);
      dispatch({ type: JOB_CREATE_SUCCESS, job: response.job.id });
      app_history.push(`/job/${response.job.id}`);
      dispatch(retrieve());
      return response.job;
    } catch (error) {
      dispatch({ type: JOB_CREATE_ERROR });
      throw error;
    }
  };
}

// REPLACE WORKER (DISPATCH PAGE)
export function replaceWorker(id, data): any {
  return async function (dispatch) {
    try {
      dispatch({ type: REPLACE_JOB_WORKER_REQUEST });
      const response = await JobsAPI.replaceWorker(id, data);
      dispatch({ type: REPLACE_JOB_WORKER_SUCCESS, shift: response.job });
      dispatch(updateDispatchPageJobs());
      return response.job;
    } catch (error) {
      dispatch({ type: REPLACE_JOB_WORKER_ERROR });
      throw error;
    }
  };
}

export function exportToCSV() {
  return async function (dispatch, getState) {
    try {
      const state = getState();
      const { requestDate, searchByEffectiveRequestDate, ...search_options } = state.jobs.search_options.asMutable();

      if (search_options) {
        if (requestDate) {
          search_options[searchByEffectiveRequestDate ? 'effectiveRequestDate' : 'requestDate'] =
            JSON.stringify(requestDate);
        }
        if (search_options.hasOwnProperty('createdDate')) {
          search_options.createdDate = JSON.stringify(search_options.createdDate);
        }
        if (
          search_options.hasOwnProperty('requestor') &&
          search_options.requestor &&
          search_options.requestor.hasOwnProperty('id')
        ) {
          search_options.requestor = search_options.requestor.id;
        }
        if (
          search_options.hasOwnProperty('department') &&
          search_options.department &&
          search_options.department.hasOwnProperty('id')
        ) {
          search_options.department = search_options.department.id;
        }
        if (
          search_options.hasOwnProperty('worker') &&
          search_options.worker &&
          search_options.worker.hasOwnProperty('id')
        ) {
          search_options.workerId = search_options.worker.id;
          search_options.worker = '';
        }
      }

      return await JobsAPI.exportJobsToCSV(search_options);
    } catch (error) {
      throw error;
    }
  };
}

export function extendWMSJobEndDate({
  end_time,
  reason,
}: {
  end_time: string;
  reason: string;
}): AppThunkAction<Promise<{ id: number }>> {
  return async function (dispatch, getState) {
    dispatch({ type: actionTypes.EXTEND_WMS_JOB_END_TIME_REQUEST });
    try {
      const job_id = getState().jobs.location_job?.id;
      await JobsAPI.extendWMSJobEndTime(job_id, { end_time, reason });
      dispatch({ type: actionTypes.EXTEND_WMS_JOB_END_TIME_SUCCESS });
      dispatch(updateDispatchPageJobs(job_id));
      dispatch(retrieveJob(job_id));
      return { id: job_id };
    } catch (error) {
      dispatch({ type: actionTypes.EXTEND_WMS_JOB_END_TIME_ERROR });
      throw error;
    }
  };
}

function updateDispatchPageJobs(job_id?: number | string, onlyTable = false): AppThunkAction<void> {
  return function (dispatch, getState) {
    const dispatchJob = getState().jobs.location_job;
    if (!dispatchJob) return;
    // Update current job if no job_id provided or if job_id matches current job
    if ((job_id && dispatchJob.id === Number(job_id)) || !job_id) {
      if (onlyTable) {
        dispatch(retrieveLocations());
        return;
      }
      dispatch(retrieveLocations());
      dispatch(retrieveLocationJob({ job_id: dispatchJob.id }));
    }
  };
}

export function assignCompanies(jobIds: number[], companyIds: number[]): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState) {
    try {
      await JobsAPI.assignCompanies(jobIds, companyIds);
      dispatch(retrieveLocations());
      dispatch(updateDispatchPageJobs());
    } catch (error) {
      throw error;
    }
  };
}

export function addJobAlert(jobId: number, alertData: JobAlertData): AppThunkAction<Promise<void>> {
  return async function (dispatch) {
    try {
      await JobsAPI.addJobAlert(jobId, alertData);
      dispatch(retrieveLocations());
      await dispatch(retrieveLocationJob({ job_id: jobId }));
    } catch (error) {
      throw error;
    }
  };
}

export function updateJobAlert(
  jobId: number,
  alertId: string | number,
  alertData: JobAlertData
): AppThunkAction<Promise<void>> {
  return async function (dispatch) {
    try {
      await JobsAPI.updateJobAlert(jobId, alertId, alertData);
      dispatch(retrieveLocations());
      await dispatch(retrieveLocationJob({ job_id: jobId }));
    } catch (error) {
      throw error;
    }
  };
}

export function deleteJobAlert(jobId: number, alertId: string | number): AppThunkAction<Promise<void>> {
  return async function (dispatch) {
    try {
      await JobsAPI.deleteJobAlert(jobId, alertId);
      dispatch(retrieveLocations());
      await dispatch(retrieveLocationJob({ job_id: jobId }));
    } catch (error) {
      throw error;
    }
  };
}

export const updateJobVerification = (data: UpdateJobVerificationRequest): AppThunkAction<Promise<void>> => {
  return async (dispatch) => {
    try {
      const response = await JobsAPI.updateJobVerification(data);
      const isSingleJob = data.job_ids.length === 1;

      if (isSingleJob) {
        const jobID = data.job_ids[0];
        await dispatch(retrieveJob(jobID, ''));
      }
      dispatch(retrieve());
      return response;
    } catch (error) {
      throw error;
    }
  };
};
